import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import './App.scss';
import { InteractionConfig } from './types';

const transition = {
	type: 'spring',
	stiffness: 200,
	mass: 0.2,
	damping: 20,
	delay: 0.5,
};

const variants = {
	initial: {
		opacity: 0,
		y: 20,
	},
	enter: {
		opacity: 1,
		y: 0,
		transition,
	},
	exit: {
		opacity: 0,
		y: 20,
		transition: {
			...transition,
			delay: 0,
		},
	},
};

let askedForConfig = false;

const imageRotation = Math.sign(Math.random() - 0.5) * (1 + Math.random() * 6);

export default function App() {
	const [loaded, setLoaded] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [config, setConfig] = useState<InteractionConfig | null>(null);
	const soundRef = useRef<HTMLAudioElement>(null);
	const narrationRef = useRef<HTMLAudioElement>(null);
	const volumeRef = useRef<number | null>(null)

	useEffect(() => {
		if (loaded) window.parent?.postMessage({ status: 'readyToPlay' }, '*');
	}, [loaded]);

	useEffect(() => {
		const onmessage = (e: any) => {
			console.log('got message from parent', e.data.cmd);
			try {
				if (e.data.cmd === 'config') {
					console.log('setting config props', e.data.config);
					const configProps = e.data.config as InteractionConfig;
					if (!configProps?.picSrc) {
						throw new Error('no src provided');
					}
					if (!configProps.timeout) {
						configProps.timeout = Number(new URLSearchParams(window.location.search).get('timeout') || '20000')
					}
					setConfig(configProps);
					volumeRef.current = e.data.volume ?? 0.7
					if (soundRef.current) soundRef.current.volume = volumeRef.current! * 0.6;
					return;
				}

				if (e.data.cmd === 'play') {
					if (!config) {
						throw new Error('no config when playing');
					}

					soundRef.current?.play();
					soundRef.current?.addEventListener("ended", () => {
						setTimeout(() => {
								if (!narrationRef.current) {
									return
								}
								if (config.Variant === "lego") {
									narrationRef.current.volume = Math.min(volumeRef.current!*0.4, 1);
								} else {
									narrationRef.current.volume = Math.min(volumeRef.current! * 1.3, 1);
								}
								narrationRef.current.play()
						}, 500)
					})
					setShowMessage(true);
					window.parent?.postMessage({ status: 'playing' }, '*');
					setTimeout(() => {
						setShowMessage(false);
						setTimeout(() => window.parent?.postMessage({ status: 'ended' }, '*'), 300);
					}, config.timeout);
				}
			} catch (error) {
				console.error('error handling message, posting to iframe');
				window.parent.postMessage({ status: 'error', error: error }, '*');
			}
		};
		window.addEventListener('message', onmessage);
		return () => {
			window.removeEventListener('message', onmessage);
		};
	}, [config]);

	// Tell the window that we are ready to config. This var state is persisted for the lifetime of the site.
	if (!askedForConfig) {
		askedForConfig = true;
		window.parent.postMessage({ status: 'readyToConfig' }, '*');
	}

	return (
		<div className="absolute-fill">
			<audio src={'/camera.mp3'} autoPlay={false} style={{ display: 'none' }} ref={soundRef} />
			{config?.TTSNarrationURL && <audio src={config.TTSNarrationURL} autoPlay={false} style={{ display: 'none' }} ref={narrationRef} />}
			<ol className={'center'}>
				<AnimatePresence>
					{config && showMessage && (
						<motion.div
							key="videodiv"
							initial="initial"
							animate="enter"
							variants={variants}
							layout
							style={{
								display: 'flex',
								alignSelf: 'flex-end',
							}}
							exit="exit"
						>
							<div className={`${config.custom ? 'custom-':''}image-container`} style={{ transform: `rotate(${imageRotation}deg)` }}>
								<img className="the-image" src={config.picSrc} alt={config.prompt}></img>
								<p className="prompt">{config.prompt}</p>
								<p className="author-name">
									<span className="author-by">By:&nbsp;</span>
									{config.msgFrom}
								</p>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</ol>

			{config?.picSrc && (
				<img
					src={config.picSrc}
					alt="a dummy"
					style={{
						width: 1,
						height: 1,
						opacity: 0,
					}}
					onLoad={() => setLoaded(true)}
					onError={() => window.parent.postMessage({ status: 'error', error: 'failed to load image' }, '*')}
				></img>
			)}
		</div>
	);
}
